import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addBuffetDishSize, deleteItem, draganddrop, editBuffetDishSizeDragandDrop, editMenuFoodItem, editNutrition, getFoodItem, getSelectExistingOptionsets } from "../../../Actions/menuActions";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import { ReduxRootState } from "../../../Interfaces/ReduxInterface/ReduxRootState";
import isEmpty from "../../../Utilities/isEmpty";
import AddEditSectionPopup from "./AddEditSectionPopup";
import OptionGroupPopup from "../Popups/OptionGroupPopup";
import OptionGroupCard from "./OptionGroupCard";
import { Dietary, DishSize, FoodItem, Ingredient, OptionSet, nutritionPayloadType } from "../../../Interfaces/PayloadsAndResponses/Menu";
import Spinner from "../../../Common/Spinner";
import { FoodItemNutrition } from "../../../Interfaces/PayloadsAndResponses/Menu";
import SelectExistingPopup from "./SelectExistingPopup";
import BuffetTable from "./BuffetTable";
import { customStyles } from "./MenuSectionsPage";
import ItemPageSkeleton from "./ItemPageSkeleton";
import ButtonSpinner from "../../../Common/ButtonSpinner";
import parse from 'html-react-parser';
import ReactTooltip from "react-tooltip";
import EditFoodItemPopUp from "./EditFoodItemPopUp";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import DeleteFoodItem from "../Popups/DeleteFoodItem";

export type buffetDishSizesData = {
  title: string;
  serve_count: string | number;
  price: string | number;
}

export type buffetDishSizesErrors = {
  title: string;
  serve_count: string | number;
  price: string | number;
}

const ItemPage = ({ linkListUpdated }: any) => {
  const dispatch: any = useDispatch()
  //data from redux
  const foodItemPageLoader = useSelector((state: ReduxRootState)=>state.menus?.foodItemPageLoader)
  const systemDietries = useSelector((state: ReduxRootState) => state.menus?.systemDietries)
  const systemIngredients = useSelector((state: ReduxRootState) => state.menus?.systemIngredients)
  const restaurant_addressesData = useSelector((state: ReduxRootState) => state.restaurant_data?.restaurant_details);
  const foodItemdata: any = useSelector((state: ReduxRootState) => state.menus?.food_item_data);
  const selectExistingOptionSets = useSelector((state: ReduxRootState) => state?.menus?.selectExistingOptionsets)
  const { i18n } = useSelector((state: ReduxRootState) => state.i18n);

  //data from local storage
  const selected_restaurant_id = localStorage.getItem('remember_restaurant') ?? ''
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? ''
  const foodItemId = localStorage.getItem('fooditemid') ?? ''
  const menuId = localStorage.getItem('menuTypeid')
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';
  const getMenutype = localStorage.getItem('menuType')

  //food item states
  const [nutritionalPopUp, setNutritionalPopUp] = useState<boolean>(false)
  const [optionsPopUp, setOptionsPopUp] = useState<boolean>(false)
  const [selectExistingPopUp, setSelectExistingPopUp] = useState<boolean>(false)
  const [isDuplicateSection, setIsDuplicateSection] = useState<boolean>(false)
  const [nutritionApiLoader, setNutritionApiLoader] = useState<boolean>(false)
  const [singleFoodItem, setSingleFoodItem] = useState(foodItemdata?.fooditem);
  const foodItemIdforOptionSet = singleFoodItem?.id
  const optionSetData: OptionSet[] = foodItemdata?.fooditem?.optionsets;
  const buffetDishSizesData: DishSize[] = foodItemdata?.fooditem?.dishsizes;
  const [nutrition, setNutrition] = useState<FoodItemNutrition[]>(foodItemdata?.fooditem?.nutritions);
  const allow_customization = foodItemdata?.fooditem?.allow_customizations;
  const [allowCustomization, setAllowCustomization] = useState<boolean>(allow_customization);
  const [systemFoodItemDiet, setSystemFoodItemDiet] = useState<Dietary[]>(systemDietries)
  const [systemFoodItemIng, setSystemFoodItemIng] = useState<Ingredient[]>(systemIngredients)
  const [editFoodItemPopUP, setEditFoodItemPopUp] = useState<boolean>(false)
  const [dragListForOptionGroups, setDragListForOptionGroups] = useState<any>(optionSetData);
  const [dragListForDishsizes, setDragListForDishSizes] = useState<any>(foodItemdata?.fooditem?.dishsizes);
  const [foodItemUpdated, setFoodItemUpdated] = useState<boolean>(false)
  const [handleBuffetDragDrop, setHandleDragDrop] = useState<boolean>(false)
  const [loading,setLoading] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);

  //Buffet States
  const [createNewDishSizes, setCreateNewDishSizes] = useState<buffetDishSizesData>({
    title: '',
    serve_count: '',
    price: '',
  });
  const [dishSizeErrors, setDishSizeErrors] = useState<buffetDishSizesErrors>({
    title: '',
    serve_count: '',
    price: '',
  });

  const restaurantId = restaurant_addressesData?.address?.id ?? +selected_restaurant_id
  useEffect(() => {
    setSingleFoodItem(foodItemdata?.fooditem)
    setNutrition(foodItemdata?.fooditem?.nutritions)
    setDragListForDishSizes(foodItemdata?.fooditem?.dishsizes)
    setDragListForOptionGroups(foodItemdata?.fooditem?.optionsets)
  }, [foodItemdata])

  useEffect(() => {
    setDragListForOptionGroups(optionSetData);
    //eslint-disable-next-line
  }, [optionSetData?.length])

  useEffect(() => {
    setDragListForDishSizes(buffetDishSizesData);
    //eslint-disable-next-line
  }, [buffetDishSizesData?.length])

  useEffect(() => {
    // Update allowCustomization if allow_customization is defined
    if (allow_customization !== undefined) {
      setAllowCustomization(allow_customization);
    }
  }, [allow_customization]);

  const handleLoading = (status: boolean) => {
    setLoading(status);
  };

  useEffect(() => {
    setLoading(true);
    setSingleFoodItem(null);
      dispatch(getFoodItem(restaurantId, +selectedMenuId, sectionid, +foodItemId, handleLoading));
    // eslint-disable-next-line
  }, [foodItemUpdated]);

  const handleBuffetDragDropLoader = (bit: boolean) => {
    setHandleDragDrop(bit)
  }


  //Get min and maximum number
  let dishSizeMinPrice = Infinity;
  let dishSizeMaxPrice = -Infinity;
  if (Array?.isArray(buffetDishSizesData) && buffetDishSizesData?.length > 0) {
    // Iterate through the array to find the minimum and maximum prices
    buffetDishSizesData?.forEach(dishsizePrice => {
      const price = (dishsizePrice?.price);
      if (!isNaN(price)) {
        if (price < dishSizeMinPrice) {
          dishSizeMinPrice = price;
        } else if (price > dishSizeMaxPrice) {
          dishSizeMaxPrice = price;
        }
      }
    });
  }

  //Delete Selected FoodItem
  const handleArchiveFoodItem = () => {
    if(singleFoodItem?.future_order_delivery_at){
      dispatch(deleteItem(restaurantId, +selectedMenuId, sectionid, +foodItemId, false, true))
    }else{
      dispatch(deleteItem(restaurantId, +selectedMenuId, sectionid, +foodItemId))
    }
  }


  const handleSelectExistingOptionSets = () => {
    setSelectExistingPopUp(true);
    restaurantId && menuId && foodItemId && dispatch(getSelectExistingOptionsets(+restaurantId, +menuId, +foodItemId))
  }

  const onPopUpCloseAddEditSection = () => {
    setIsDuplicateSection(false)
  }

  const handlePopUpOpen = (status: boolean) => {
    setOptionsPopUp(status);
  };

  //Allow Custmization Functionaility
  const handleCustomizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allowCustomizationToggleVale = event.target.checked;
    setAllowCustomization(allowCustomizationToggleVale);
    const allowCustomizationPayload = {
      fooditem: {
        allow_customizations: allowCustomizationToggleVale,
      }
    };
    menuId && restaurantId && dispatch(editMenuFoodItem(allowCustomizationPayload, menuId, restaurantId, sectionid, foodItemId))
  };
  const handlePopUpClose = () => {
    setOptionsPopUp(false);
  };

  const handleEditDelete = () => {
    // setEditDelete(true);
  };

  const onPopUpCloseNutritionFacts = () => {
    setNutritionalPopUp(false)
    setNutritionApiLoader(false)
  }

  useEffect(() => {
    setNutrition(foodItemdata?.fooditem?.nutritions)
  }, [foodItemdata])

  const handleValueChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let newValue = +value;
    if (newValue < 0) {
      newValue = 0;
    }
    const updatedNutritions = [...nutrition];
    updatedNutritions[index] = { ...updatedNutritions[index], value: newValue };
    setNutrition(updatedNutritions);
  };

  const handleSubmit = () => {
    const filteredNutrition = nutrition?.map(({ id, value }: { id: number, value: string | number }) => ({
      id,
      value: +value
    }));
    setNutritionApiLoader(true)
    const nutritionPayload: nutritionPayloadType = {
        fooditem:
        {
          id: +foodItemId,
          nutritional_facts_attributes: [
            ...filteredNutrition
          ]
        }
    }
    dispatch(editNutrition(restaurantId, +selectedMenuId, sectionid, +foodItemId, nutritionPayload, onPopUpCloseNutritionFacts))
  };

  const validateBuffetDishSizeFormData = () => {
    const showDishsizeErrors: any = {};
    if (!createNewDishSizes.title) {
      showDishsizeErrors.title = 'Title is required';
    }
    if (!createNewDishSizes.serve_count) {
      showDishsizeErrors.serve_count = 'Serve count is required';
    } else if (+createNewDishSizes.serve_count <= 0) {
      showDishsizeErrors.serve_count = 'Serve count must be greater than 0';
    }
    if (!createNewDishSizes.price) {
      showDishsizeErrors.price = 'Price is required';
    }
    setDishSizeErrors(showDishsizeErrors);
    return Object.keys(showDishsizeErrors).length === 0; // Return true if there are no errors
  };

  const getUpdatedFooditem = () => {
    setFoodItemUpdated(!foodItemUpdated)
  }
//Handle change for buffet
  const handleChangeBuffetDishsize = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCreateNewDishSizes((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  //handle submit for buffet dishsizes
  const handleSubmitNewDisSizes = () => {
    const { title, serve_count, price } = createNewDishSizes;
    if (!validateBuffetDishSizeFormData()) {
      return;
    }
    const bufftDishsizePayload = {
      dishsize: {
        title: title,
        serve_count: serve_count,
        price: price,
        position: 1
      }
    };
    restaurantId && menuId && dispatch(addBuffetDishSize(bufftDishsizePayload, menuId, restaurantId, sectionid, +foodItemId))
    setCreateNewDishSizes({
      title: '',
      serve_count: '',
      price: '',
    });
  }

  const dietriesIngredientsHandle = (singleFoodItem: FoodItem) =>{
    setEditFoodItemPopUp(true)
    let dietry = [...singleFoodItem?.dietaries]
    const updatedDiet = [...systemDietries]
    dietry?.forEach((selectedDietry) => {
      systemDietries?.find((systemFoodItemDietDietry, id) => {
        if (selectedDietry?.id === systemFoodItemDietDietry?.id) {
          updatedDiet[id] = { ...updatedDiet[id], selected: true };
          setSystemFoodItemDiet(updatedDiet)
        }
        return selectedDietry?.id === systemFoodItemDietDietry?.id
      })
      setEditFoodItemPopUp(true)
    })
    let ingredient = [...singleFoodItem?.ingredients]
    const updatedIngredient = [...systemIngredients]
    ingredient?.forEach((selectedIngredient) => {
      systemIngredients?.find((systemFoodItemDietDietry, id) => {
        if (selectedIngredient?.id === systemFoodItemDietDietry?.id) {
          updatedIngredient[id] = { ...updatedIngredient[id], selected: true };
          setSystemFoodItemIng(updatedIngredient)
        }
        return selectedIngredient?.id === systemFoodItemDietDietry?.id
      })
      setEditFoodItemPopUp(true)
    })
  }
  const handleOnDragEndOptionGroups = (result: any) => {
    if (!result?.destination) return;
    const items = Array?.from(dragListForOptionGroups);
    const [reorderedItem] = items?.splice(result?.source?.index, 1);
    items?.splice(result?.destination?.index, 0, reorderedItem);
    setDragListForOptionGroups(items);
    const destination = result?.destination;
    const newPosition = dragListForOptionGroups && dragListForOptionGroups[destination?.index]?.position;
    const draggableOptionSet = dragListForOptionGroups?.find(
      (sectionId: any) => sectionId?.id === parseInt(result?.draggableId)
    );
    const payload = {
      optionset: {
        position: newPosition
      },
    };
    selectedMenuId && restaurant_addressesData && dispatch(draganddrop(payload, selectedMenuId, +restaurantId, sectionid, +foodItemId, draggableOptionSet?.id))
  };

  const handleOnDragEndBuffetDishSize = (result: any) => {
    if (!result?.destination) return;
    const items = Array?.from(dragListForDishsizes);
    const [reorderedItem] = items?.splice(result?.source?.index, 1);
    items?.splice(result?.destination?.index, 0, reorderedItem);
    setDragListForOptionGroups(items);
    const destination = result?.destination;
    const newPosition = dragListForDishsizes && dragListForDishsizes[destination?.index]?.position;
    const selectedDishSizeId = dragListForDishsizes?.find(
      (sectionId: any) => sectionId?.id === parseInt(result?.draggableId)
    );
    const payload = {
      dishsize: {
        position: newPosition,
      },
    };
    handleBuffetDragDropLoader(true)

    selectedMenuId && restaurantId && dispatch(editBuffetDishSizeDragandDrop(payload, selectedMenuId, +restaurantId, sectionid, +foodItemId, selectedDishSizeId?.id, () => handleBuffetDragDropLoader(false)))
  };

  const handleFoodItemArchive = () => {
    if (singleFoodItem?.future_order_delivery_at){
      return setOpenDeletePopup(true)
    }else{
      handleArchiveFoodItem()
    }
  }


  return (
    <div className="food-items-page-container">
      <section>
        <div className="mb-1">
          <Breadcrumbs linkList={linkListUpdated} />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <Link to={`/dashboard/restaurant/${restaurantId}/items/`} style={{ color: '#fff', marginBottom: '20px'}}>
              <i className='fa-solid fa-angle-left mr-2'></i>
            </Link> 
          <h3 className="text-white section-heading section-container">{singleFoodItem?.name?.charAt(0)?.toUpperCase() + singleFoodItem?.name?.slice(1)}</h3>
          </div>
        <div className="btn-group">
          <button type="button" className="btn btn-custom-outline-circle rounded-circle dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="far fa-ellipsis-h fa-lg" style={{color: "#157FFC"}}></i>
          </button>
          <ul className="dropdown-menu">
            <li><span className="dropdown-item" onClick={() => { setIsDuplicateSection(true) }}><i className="far fa-clone me-2"></i>Duplicate</span></li>
            <li><span className="dropdown-item" onClick={handleFoodItemArchive}><i className="fas fa-trash-alt me-2"></i>Archive</span></li>
          </ul>
        </div>
        </div>
      </section>
      { <div className="container px-0 ">
        <div className="bg-white rounded-10">
          <div className="header_section d-flex justify-content-between align-items-center px-2 px-md-4 py-3 border-bottom">
            <h3 className="fw-600 m-0 text-secondry-gray">Item Info</h3>
            <button type="button" className="btn btn-custom-outline-light rounded-pill px-4" data-bs-dismiss="modal" onClick={()=>dietriesIngredientsHandle(singleFoodItem)}>Edit</button>
          </div>
          <div className="menu_show_card d-flex row ">
            {loading ? (
              <div className="d-flex flex-wrap">
                <div className="col-lg-5 col-md-6 col-sm-12 px-0 food-img-wrapper rounded-10">
                  <Skeleton className="h-100 w-100" height={250} width={400} />
                </div>
                <div className="col-12 col-md-6 mt-3 mt-md-0">
                  <Skeleton className="ps-0 ps-md-4 col-lg-7 col-md-6 col-sm-12" height={30} width={100} />
                  <Skeleton className="ps-0 ps-md-4 col-lg-7 col-md-6 col-sm-12 mt-3" height={30} width={120} />
                </div>
              </div>
            ) : (
            <div className="col-lg-5 col-md-6 col-sm-12 px-0 food-img-wrapper rounded-10">
              {!isEmpty(singleFoodItem?.image?.medium?.url ?? singleFoodItem?.image?.url ?? singleFoodItem?.image?.url) ? <img alt='food item' src={singleFoodItem?.image?.medium?.url ?? singleFoodItem?.image?.url ?? singleFoodItem?.image?.url ?? ''}
                className='p-0 img-fluid rounded-10 h-100 w-100'/> :
                <img src="/imgs/menu-page-place-holder1.svg" alt="" className="p-0 img-fluid rounded-10 h-100 w-100" />
              }
              </div>)}
            {
            loading ? (
              <div className="ps-0 ps-md-4 mt-3 mt-md-0 col-lg-7 col-md-6 col-sm-12">
            </div>
            ) : (
            <div className="ps-0 ps-md-4 mt-3 mt-md-0 col-lg-7 col-md-6 col-sm-12">
              <h5 className="lead fw-600 text-medium-black m-0 mb-1">{singleFoodItem?.name}</h5>
              {getMenutype === 'buffet' && (
              <p className="fs-18 fw-normal text-medium-black m-0 mb-2">
                {(isFinite(dishSizeMinPrice) && isFinite(dishSizeMaxPrice)) ? `$ ${dishSizeMinPrice} - $ ${dishSizeMaxPrice}` : 'No dishsize available'}
              </p>
            )}
              <p className="fs-18 fw-normal mb-4 text-medium-gray">{singleFoodItem?.description}</p>
              {(singleFoodItem?.dietaries?.length > 0 || singleFoodItem?.ingredients?.length > 0) && <div>
                <p className="text-medium-gray text-uppercase fw-600 m-0">TAGGING</p>
                <div className="d-flex flex-wrap align-items-center me-4 mt-2 mb-3 gap-2">
                  {singleFoodItem?.dietaries?.length > 0 && singleFoodItem?.dietaries?.map((dietary: any, index: number) => (
                    <div
                      data-tip
                      data-for={`dietary-${index}`} // Use a unique identifier for each tooltip
                      key={index}
                      style={{height:'40px', width:'40px'}}
                    >
                      <div key={index}>
                        <span
                          className="menu_card_ingradient_wrapper_item_page d-inline-flex justify-content-center align-items-center" >
                          {dietary?.logo ? <div className="menu_card_ingradient_wrapper_item_page d-inline-flex justify-content-center align-items-center ml-1">{parse(dietary.logo)}</div> : <div className="text-uppercase tagging-text">{dietary?.name?.charAt(0)}</div>}
                        </span>
                      </div>
                      {window.navigator.userAgent.match(/MSIE|Trident/) === null && (
                        singleFoodItem?.dietaries?.map((dietary: any, index: any) => (
                          <ReactTooltip key={`tooltip-${index}`} type='dark' place='top' effect='solid' className='react-tooltip' id={`dietary-${index}`}>
                            <span>{dietary?.name}</span>
                          </ReactTooltip>
                        ))
                      )}
                    </div>
                  ))}
                  {singleFoodItem?.ingredients?.length > 0 && singleFoodItem?.ingredients?.map((ingredient: any, index: number) => (
                    <div
                      data-tip
                      data-for={`ingredient-${index}`} // Use a unique identifier for each tooltip
                      key={index}
                      style={{height:'40px', width:'40px'}}
                    >
                      <div key={index}>
                        <span
                          className="menu_card_ingradient_wrapper_item_page d-inline-flex justify-content-center align-items-center ml-1" >
                          {ingredient?.logo ? <div className="menu_card_ingradient_wrapper_item_page d-inline-flex justify-content-center align-items-center ml-1">{parse(ingredient?.logo)}</div> : <div className="text-uppercase tagging-text">{ingredient?.name.charAt(0)}</div>}
                        </span>
                      </div>
                      {window.navigator.userAgent.match(/MSIE|Trident/) === null && (
                        singleFoodItem?.ingredients?.map((ingredient: any, index: number) => (
                          <ReactTooltip key={`tooltip-${index}`} type='dark' place='top' effect='solid' className='react-tooltip' id={`ingredient-${index}`}>
                            <span>{ingredient?.name}</span>
                          </ReactTooltip>
                        ))
                      )}
                    </div>
                  ))}
                </div>
              </div>}

              <div>
                <p className="text-medium-gray text-uppercase fw-600 m-0">VISIBILITY</p>
                <div className="d-flex  align-items-center ">
                  {singleFoodItem?.is_hidden ? <>
                    <span><i className="fas fa-solid fa-eye-slash hidden-eye-class"></i></span>
                    <p className="text-medium-gray fs-18 fw-normal ms-2 mb-0">Hidden</p>
                  </>
                    :
                    <>
                      <span><i className="fa-solid fa-eye"></i></span>
                      <p className="text-medium-gray fs-18 fw-normal ms-2 mb-0">Visible</p>
                    </>
                  }
                </div>
              </div>
            </div> )}
          </div>
        </div>
      </div>}
      {/* Edit foodITEM */}
      {editFoodItemPopUP && <EditFoodItemPopUp
        editFoodItemPopUP={editFoodItemPopUP}
        setEditFoodItemPopUp={setEditFoodItemPopUp}
        singleFoodItem={singleFoodItem}
        foodItemDiet={systemFoodItemDiet}
        foodItemIng={systemFoodItemIng}
        getUpdatedFooditem={getUpdatedFooditem}
      />}

      <div className="container px-0 my-5">
        <div className="bg-white rounded-10">
          <div className="px-4 py-3">
          <h3 className="fw-600 text-secondry-gray">Customization</h3>
            {
              loading ?
                <Skeleton className="pt-2" height={30} width={400} />
                :
                <div className="pt-2">
          <label>
            <input
              type="checkbox"
              checked={allowCustomization}
              onChange={handleCustomizationChange}
            />
            <span className="ml-2">Allow Customization requests (i.e Extra Instructions).</span>
          </label>
        </div>}
        </div>
        </div>
      </div>
      {/* Buffet table */}
      {getMenutype === 'buffet' && <div className="container px-0 my-5">
        <div className="bg-white rounded-10">
          <div className="header_section px-4 py-3 border-bottom">
            <h3 className="fw-600 text-secondry-gray">Buffet Dish size & Cost</h3>
            <span className="buffet-description">Atleast one dish must be enabled for a user to be able to place order</span>
          </div>
          <div className="w-100 pb-4">
            <div className="w-100">
              <div className="d-flex py-2">
                  <div className="col-lg-1 border-0"></div>
                  <div className="col-lg-5 buffet-header-content w-50">Title</div>
                  <div className="col-lg-2 buffet-header-content">Serve</div>
                  <div className="col-lg-2 buffet-header-content">Cost($)</div>
                  <div className="col-lg-2"></div>
              </div>
              <div>

                {handleBuffetDragDrop ? <div>
                  {[1, 2, 3]?.map((val, index) => <React.Fragment key={val * index + 100}>
                    <td className="pl-4 pr-4"><Skeleton height={35} width={200} /></td>
                    <td className="pl-4 pr-4"><Skeleton height={35} width={300} /></td>
                    <td className="pl-4 pr-4"><Skeleton height={35} width={200} /></td>
                    <br />
                  </React.Fragment >)}
                </div> :
                  <DragDropContext onDragEnd={handleOnDragEndBuffetDishSize}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {foodItemPageLoader ? (
                            <ItemPageSkeleton />
                          ) : (
                            dragListForDishsizes && dragListForDishsizes?.map((dishsizes: any, index: any) => (
                              <Draggable draggableId={String(dishsizes?.id)} index={index} key={String(dishsizes?.id)}>
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    // {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >

                                    <BuffetTable dragProps={provided.dragHandleProps} dishsizes={dishsizes} />
                                  </div>
                                )}
                              </Draggable>
                            ))
                          )}
                          {provided?.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                }
                <div>
                  <div className="text-center">
                    <div className="p-4 mt-4 mx-4 buffet_dishsizes_add_area position-relative d-flex align-items-center justify-content-center">
                      <svg className="svg-inline--fa fa-plus fa-lg" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                        <path fill="currentColor" d="M240 64c0-8.8-7.2-16-16-16s-16 7.2-16 16V240H32c-8.8 0-16 7.2-16 16s7.2 16 16 16H208V448c0 8.8 7.2 16 16 16s16-7.2 16-16V272H416c8.8 0 16-7.2 16-16s-7.2-16-16-16H240V64z"></path>
                      </svg>
                      <div className="row menu_list_button_area w-100 align-items-center mx-5">
                        <div className='text-right col-5'>
                          <label className='text-left align-top text-nowrap text-medium-gray pb-1 w-75'>
                            <span>Title</span>
                            <input
                              type='text'
                              name='title'
                              className='form-control mt-1 py-2 px-4 rounded'
                              placeholder='X Large'
                              value={createNewDishSizes?.title}
                              onChange={handleChangeBuffetDishsize}
                            />
                            {dishSizeErrors?.title && <div className="invalid-feedback-password">{dishSizeErrors?.title}</div>}
                          </label>
                        </div>
                        <div className='text-right col-2'>
                          <label className='text-left align-top text-nowrap text-medium-gray pb-1'>
                            <span>Serve</span>
                            <input
                              min={1}
                              type='number'
                              name='serve_count'
                              className='form-control mt-1 py-2 px-4 rounded'
                              placeholder='35'
                              value={createNewDishSizes?.serve_count}
                              onChange={handleChangeBuffetDishsize}
                            />
                            {dishSizeErrors?.serve_count && <div className="invalid-feedback-password">{dishSizeErrors?.serve_count}</div>}
                          </label>
                        </div>
                        <div className='text-right col-2'>
                          <label className='text-left align-top text-nowrap text-medium-gray pb-1'>
                            <span>Cost($)</span>
                            <input
                              type='number'
                              name='price'
                              className='form-control mt-1 py-2 px-4 rounded'
                              placeholder='250'
                              value={createNewDishSizes?.price}
                              onChange={handleChangeBuffetDishsize}
                            />
                            {dishSizeErrors?.price && <div className="invalid-feedback-password">{dishSizeErrors?.price}</div>}
                          </label>
                        </div>
                        <div className='text-right col-2'>
                          <button type="button" className="btn btn-custom-primary rounded-pill px-4 py-2" onClick={handleSubmitNewDisSizes}>
                            {!foodItemPageLoader ? 'Add' : <ButtonSpinner />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

      <div className="container px-0 my-5 ">
        <div className="bg-white rounded-10">
          <div className="header_section d-flex justify-content-between align-items-center px-4 py-3 border-bottom">
            <h3 className="fw-600 m-0 text-secondry-gray">Option Groups</h3>
            <div className="btn-group">
              <button type="button" className="btn btn-custom-outline-light rounded-pill dropdown-toggle px-4 py-2 " data-bs-toggle="dropdown" aria-expanded="false" >
                New Option Group
              </button>
              <ul className="dropdown-menu">
                <li><span className="dropdown-item" onClick={() => setOptionsPopUp(true)}>Add New Option Group</span></li>
                <li><span className="dropdown-item" onClick={handleSelectExistingOptionSets}>Select Existing</span></li>
              </ul>
            </div>
          </div>
          <div className="drag-drop-container">
            {loading ?
              <div className="skeleton-placeholder">
                <Skeleton className="skeleton-item" height={50} width="100%" />
                <Skeleton className="skeleton-item" height={50} width="100%" />
                <Skeleton className="skeleton-item" height={50} width="100%" />
              </div>
              :
              <DragDropContext onDragEnd={handleOnDragEndOptionGroups}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="drag-drop-content">
                    {foodItemPageLoader ? (
                      <ItemPageSkeleton />
                    ) : (
                      dragListForOptionGroups && dragListForOptionGroups?.map((options: any, index: any) => (
                        <Draggable draggableId={String(options?.id)} index={index} key={String(options?.id)}>
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              // {...provided.dragHandleProps} // used for draging moved it to drag icon for precise draging
                              ref={provided.innerRef}
                              className="draggable-item"
                            >
                              <div className="d-flex container px-0">
                                <div {...provided.dragHandleProps} style={{ maxWidth: '20px' }} className="d-flex justify-content-center align-items-center px-0 col-1">
                                  <span className="ml-1" style={{ zIndex: 2 }}>
                                    <i className="fa-regular fa-grip-dots-vertical fa-lg drage_buttons"></i>
                                  </span>
                                </div>
                                <div className="col-12 px-0" style={{ marginLeft: '-20px', marginRight: '-20px' }} >
                                  <OptionGroupCard optionGroupData={options} />
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))
                    )}
                    {provided?.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>}
          </div>
          {/* <div className="menu_show_card ">
            <p className="menu_card_description mb-4">
              Does your dish have any options or extras? e.g. size, toppings, ingredients.
            </p>
            <div className="btn-group">
              <button type="button" className="btn btn-custom-outline-light rounded-pill dropdown-toggle px-4 py-2" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setOptionsPopUp(true)}>
                New Option Group
              </button>
              <ul className="dropdown-menu">
                <li><span className="dropdown-item">New Dish</span></li>
                <li><span className="dropdown-item">Select Existing</span></li>
              </ul>
            </div>
          </div> */}
            {!foodItemPageLoader && <div className="p-4 mt-4 mx-4 menu_list_add_area position-relative">
              <i className="fa-light fa-plus fa-lg"></i>
              <div className="d-flex flex-wrap align-items-center justify-content-center menu_list_button_area">
                <button className="btn btn-lite-primary" onClick={() => setOptionsPopUp(true)}>Add options</button>
                <button className="btn btn-lite-primary" onClick={handleSelectExistingOptionSets}>Select Existing</button>
              </div>
            </div>}
            <div className="pb-4"></div>
        </div>
      </div>

      {nutrition?.length > 0 && <div className="container px-0 my-5 ">
        <div className="bg-white rounded-10">
          <div className="header_section d-flex justify-content-between align-items-center px-4 py-3 border-bottom">
            <h3 className="fw-600 m-0 text-secondry-gray">Nutritional Facts</h3>
            <button className="btn btn-custom-outline-light me-2 rounded-pill px-4 d-flex" onClick={() => setNutritionalPopUp(true)}>
              {/* <i className="fa-thin fa-plus pe-2"></i> */}Edit Nutritional Facts
            </button>
          </div>
          <div className="menu_show_card ">
            <div className="row">{nutrition?.map((nutrition: FoodItemNutrition, index: number) => (
              <div className="col-lg-3 col-md-12 col-sm-1 mb-3" key={index}>
                <h5 className="lead fw-600 text-medium-black m-0 text-nowrap">{nutrition?.name}</h5>
                <p className="fs-18 fw-normal flex-wrap text-medium-black m-0 d-flex">{(+nutrition?.value)?.toFixed(1)}</p>
              </div>))}
            </div>
            {/* <button className="btn btn-link me-2 rounded-pill ps-0 py-3 d-flex" onClick={() => setNutritionalPopUp(true)}> */}
              {/* <i className="fa-thin fa-plus pe-2"></i> */}
              {/* Edit Nutritional Facts
            </button> */}
          </div>
        </div>
      </div>}

      {nutritionalPopUp ? <CustomPopUp
        backdropStyles={{ opacity: 1, cursor: 'default' }}
        onPopUpClose={() => setNutritionalPopUp(false)}
        // popUpClassName='order-history-range-picker'
        popUpBodyStyles={{ ...customStyles, top: '25%' }}
       allowScrolling={true}
      >
        <div className="modal-content ">
          <div className="menu-item-modal-header">
            <h4 className="custome-modal-title modal-title">{i18n && i18n?.t('Nutritional Facts')}</h4>
            <button type="button" className="btn-close modal-close-btn text-dark" data-bs-dismiss="modal" aria-label="Close" onClick={() => setNutritionalPopUp(false)}></button>
          </div>
          <div className="selection_modal_body">
            <form onSubmit={handleSubmit}>
              <div className="row">{nutrition?.map((nutrition: FoodItemNutrition, index: number) => (
                <div className="col-lg-4 col-md-12 col-sm-1 mt-4" key={+nutrition?.nutrition_id} style={{padding: '0px 12px'}}>
                  <label htmlFor={`value-${index}`} className="menu-item-modal-labels mb-1">{nutrition?.name}:</label>
                  <input
                    type="number"
                    className="form-control form-control-sm rounded-1"
                    id={`value-${index}`}
                    name="value"
                    value={nutrition?.value ?? 0}
                    onChange={(e) => handleValueChange(index, e)}
                  />
                </div>))}
              </div>
            </form>
          </div>
          <div className="menu-item-modal-footer justify-content-end">
            <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill px-4" data-bs-dismiss="modal" onClick={() => setNutritionalPopUp(false)}>Cancel</button>
            {nutritionApiLoader ? <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32"><Spinner /></button> : <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32" onClick={handleSubmit}>Add</button>}
          </div>
        </div>
      </CustomPopUp> :
        <></>}
      {/* Option Group Popup */}
        <div>
          <OptionGroupPopup
            optionsPopUp={optionsPopUp}
            onClose={handlePopUpClose}
            onAddOptionGroup={handlePopUpOpen}
            // onSelectExistingOptionGroup={handleSelectExistingPopUpOpen}
            onEditDelete={handleEditDelete}
            foodItemIdforOptionSet={foodItemIdforOptionSet}
          />
        </div>
        {/* Select Existing Popup */}
        <div>
          <SelectExistingPopup
            selectExistingPopUp={selectExistingPopUp}
            setSelectExistingPopUp={setSelectExistingPopUp}
            selectExistingOptionSets={selectExistingOptionSets}
          />
        </div>
      {(isDuplicateSection) && <AddEditSectionPopup foodItem={singleFoodItem} sectionFoodItemCard={true} type={'Duplicate'} onPopUpClose={onPopUpCloseAddEditSection} fromSectionFoodItemCard={true}/>}
      <DeleteFoodItem
        fooditemFutureDate={singleFoodItem?.future_order_delivery_at ? singleFoodItem?.future_order_delivery_at : ''}
        openDeletePopup={openDeletePopup}
        setOpenDeletePopup={setOpenDeletePopup}
        onDeleteConfirmed={handleArchiveFoodItem}
        loading={loading}
      />
    </div>
  )
}
export default ItemPage;