import React, { FC } from "react";
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import { customStyles } from "../MenuPages/MenuSectionsPage";
import './popupstyles.css'
import { getOrderTimeZone } from "../../../Utilities/getZonalTime";
import Spinner from "../../../Common/Spinner";

type DeleteFoodItemProps = {
  fooditemFutureDate: string;
  openDeletePopup: boolean;
  setOpenDeletePopup: (status: boolean) => void;
  onDeleteConfirmed: () => void;
  loading?: boolean;
};

const DeleteFoodItem: FC<DeleteFoodItemProps> = ({
  fooditemFutureDate,
  openDeletePopup,
  setOpenDeletePopup,
  onDeleteConfirmed,
  loading
}) => {

  return (
    <div className="container">
      {openDeletePopup && (
        <CustomPopUp
          backdropStyles={{ opacity: 1, cursor: "default" }}
          onPopUpClose={() => setOpenDeletePopup(false)}
          popUpBodyStyles={{ ...customStyles, top: "25%" }}
          allowScrolling={true}
        >
          <div className="delete_modal_content">
            <div className="delete_modal_header d-flex ml-auto">
              <button
                type="button"
                className="btn-close modal-close-btn text-dark ml-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setOpenDeletePopup(false)}
              ></button>
            </div>
            <div className="delete_modal_body">
              <div className="d-flex justify-content-center flex-column">
                {/* <div className="d-flex justify-content-center pb-4">
                  <i className="fa-regular fa-trash-can text-red-tertiary fa-lg py-3 px-2 delete-wrapper rounded-circle"></i>
                </div> */}
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="pb-3 fw-bold m-0 text-medium-black">
                    <span className="p-2"><i className="fa-regular fa-triangle-exclamation"></i></span>Warning: Future Orders Exist
                  </h3>
                  <p className="fs-18 text-secondary-gray fw-normal text-center">
                    Note that this item has already been ordered on  <b> {fooditemFutureDate ? getOrderTimeZone(fooditemFutureDate ?? '','MM/dd/yyyy h:mma')?.split(' ')[0] : ''}. </b>
                    If you would like to delete the future order, please notify <span>
                    <a href="mailto:support@chowmill.com">support@chowmill.com</a>
                    </span>
                  </p>
                </div>
                <div className="delete_modal_footer d-flex flex-column flex-sm-row justify-content-center">
                  <button
                    type="button"
                    className="btn fs-18 fs-sm-26 fw-600 light-gray me-2 rounded-pill w-100"
                    onClick={() => setOpenDeletePopup(false)}
                  >
                    No, Don’t Delete
                  </button>
                  <button
                    type="button"
                    className="btn fs-18 fs-sm-26 fw-600 rounded-pill bg-red-tertiary text-white confirm-delete w-100"
                    onClick={() => onDeleteConfirmed()}
                  >
                    {loading ? <Spinner /> : `Yes, Delete`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CustomPopUp>
      )}
    </div>
  );
};

export default DeleteFoodItem;
